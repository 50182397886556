function getCookie(name) {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
};


$(document).ready(function () {
  let changeLanguages = $('.change-languages');
  let langBox = $('.lang-box');
  let closure = $('.lang-box .closure');

  let language = getCookie('language')

  let text = () => {
    if (!language)return 'CN'

    switch (language) {
      case 'pt':
        return 'PT'
      case 'en':
        return 'EN'
      default:
        return 'CN'
    }
  }

  changeLanguages.text(text() || 'CN')

  changeLanguages.click(function () {
    langBox.css({
      display: 'block'
    })
  });

  closure.click(function () {
    langBox.css({
      display: 'none'
    })
  });

  const getHref = () => {
    const href = window.location.pathname
    const search = window.location.search
    let language = getCookie('language')
    if(!language){
      return '/index.html' + search
    }else if(language !== 'zh'){
      return href.slice(3) + search
    }else{
      return href + search
    }
  }

  $('#english').click(function(){
    var href = getHref()
       document.cookie = "language=en; domain=" + process.env.NODE_AREA +"; path=/";
       location.href = '/en' + href
  })

  $('#chinese').click(function(){
    var href = window.location.pathname
    const search = window.location.search
    document.cookie = "language=zh; domain=" + process.env.NODE_AREA + "; path=/";
    location.href =  href + search
  })
  $('#portuguese').click(function(){
    var href = getHref()
    document.cookie = "language=pt; domain=" + process.env.NODE_AREA + "; path=/";
    location.href = '/pt' + href;
  })


})



const init = () => {
  let language = getCookie('language')
  let languages = ['zh', 'en', 'pt']
  let pathArr = location.pathname.split('/')
  let path = pathArr[pathArr.length - 1]
  const toLocation = (href) => {
    if (location.origin + location.pathname !== href) {
      location.href = href
    }
  }

  if (language && language !== 'zh') {
    let href = location.origin + '/' + language + '/' + path
    toLocation(href)
  } else if (language && language === 'zh') {
    let href = location.origin + '/' + path
    toLocation(href)
  } else {
    let language = navigator.language.split('-')[0]
    let bool = languages.some(item => item === language)
    if (bool && language !== 'zh') {
      let href = location.origin + '/' + language + '/' + path
      toLocation(href)
    } else if (bool && language === 'zh') {
      let href = location.origin + '/' + path
      toLocation(href)
    } else {
      let href = location.origin + '/en' + '/' + path
      toLocation(href)
    }
  }
}


init()